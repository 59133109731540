import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSheet(ctx, { sheetId, params }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/sheet`, { params })
          // .get(`accounting/sheets/${sheetId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    downloadSheet(ctx, { sheetId, params }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/sheet/download`, { params, responseType: 'blob' })
          // .get(`accounting/sheets/${sheetId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    modifySheet(ctx, { sheetId, workbook, mode }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/sheet/modify`, workbook, { params: { mode } })
          // .get(`accounting/sheets/${sheetId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    importSession(ctx, { sessionId }) {
      return new Promise((resolve, reject) => {
        axios
          .put('accounting/receivable/cohorts', { session: sessionId })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchTemplates(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('accounting/receivable/templates', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchTemplate(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`accounting/receivable/templates/${id}`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addTemplate(ctx, { templateData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`accounting/receivable/templates`, templateData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateTemplate(ctx, { id, templateData }) {
      // Sanitize the request body before sending it the server to prevent strict validation from killing the request
      delete templateData.id;
      delete templateData.createdAt;
      delete templateData.updatedAt;

      return new Promise((resolve, reject) => {
        axios
          .patch(`accounting/receivable/templates/${id}`, templateData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAccounts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('accounting/receivable/accounts', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAccount(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`accounting/receivable/accounts/${id}`, { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addAccount(ctx, accountData) {
      return new Promise((resolve, reject) => {
        axios
          .post('accounting/receivable/accounts', accountData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addRefundToAccount(ctx, { accountId, refundData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`accounting/receivable/accounts/${accountId}/refunds`, refundData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addPaymentToAccount(ctx, { accountId, paymentData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`accounting/receivable/accounts/${accountId}/payments`, paymentData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removePaymentFromAccount(ctx, { accountId, paymentId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`accounting/receivable/accounts/${accountId}/payments/${paymentId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addChargeToAccount(ctx, { accountId, chargeData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`accounting/receivable/accounts/${accountId}/charges`, chargeData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    removeChargeFromAccount(ctx, { accountId, chargeId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`accounting/receivable/accounts/${accountId}/charges/${chargeId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    applyTemplateToAccount(ctx, { accountId, templateId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`accounting/receivable/accounts/${accountId}/templates`, { templateId })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createReportFromAccount(ctx, { accountId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`accounting/receivable/accounts/${accountId}/report`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateAccount(ctx, { id, accountData }) {
      // Sanitize the request body before sending it the server to prevent strict validation from killing the request
      delete accountData.id;
      delete accountData.createdAt;
      delete accountData.updatedAt;

      return new Promise((resolve, reject) => {
        axios
          .patch(`accounting/receivable/accounts/${id}`, accountData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCohorts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('accounting/receivable/cohorts', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCohort(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`accounting/receivable/cohorts/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCohortSummary(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`accounting/receivable/cohorts/${id}/summary`, { params: { populate: 'session' } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportCohortXlsx(ctx, { id, params }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`accounting/receivable/cohorts/${id}/export`, { params, responseType: 'blob' })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    exportCohortFinancialReports(ctx, { id, payload, params }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`accounting/receivable/cohorts/${id}/financial-reports/queue`, payload, { params })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addCohort(ctx, cohortData) {
      return new Promise((resolve, reject) => {
        axios
          .post('accounting/receivable/cohorts', cohortData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateCohort(ctx, { id, cohortData }) {
      // Sanitize the request body before sending it the server to prevent strict validation from killing the request
      delete cohortData.id;
      delete cohortData.createdAt;
      delete cohortData.updatedAt;

      return new Promise((resolve, reject) => {
        axios
          .patch(`accounting/receivable/cohorts/${id}`, cohortData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
